import React from 'react'
import InvoicePage from './InvoicePage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import CreatedInvoiceValidate from "./create/CreatedInvoiceValidate"

function InvoiceContainer(props) {
    const urlParams = parseSearchQuery()
    const {page = 1, limit = 20} = urlParams
    const currentPageParsed = Number(page)
    const currentLimitParsed = Number(limit)

    return (
        <DashboardLayout path={props.match.path}>
            <div className="InvoiceContainer mt-3">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">Invoice validation</h1>

                    <CreatedInvoiceValidate/>
                </div>
                <div className="site-layout-background p-3">
                    <InvoicePage
                        currentPage={currentPageParsed}
                        currentLimit={currentLimitParsed}
                    />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default InvoiceContainer
