import React, {Component} from 'react'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import RelabelsPage from './RelabelsPage'
import CreateRelabel from './create/CreateRelabel'

class RelabelsContainer extends Component {
    render() {
        return <DashboardLayout path={this.props.match.path}>
            <div className="RelabelsContainer mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">Relabels</h1>
                    <CreateRelabel/>
                </div>
                <div className="site-layout-background p-3">
                    <RelabelsPage/>
                </div>
            </div>
        </DashboardLayout>
    }
}

export default RelabelsContainer