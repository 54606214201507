import React, {Component} from 'react'

class HeaderApp extends Component{
    render() {
        return (
            <div className="siteHeader d-flex justify-content-between align-items-center bg-white p-3">
                <h2 className="mb-0">Labels Hub</h2>
                <a href="https://docs.google.com/document/d/1ntztt7SfkYwtTfKzGn_c_KqHHSWggzJgrY7lias3W5A/edit#" rel="noopener noreferrer"><i className="fas fa-life-ring"/> Help</a>
            </div>
        )
    }
}

export default HeaderApp