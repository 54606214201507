import React, {Component} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/_index.scss'
import {Router, Route, Switch} from 'react-router-dom'
import InvoiceContainer from './app/invoice/components/InvoiceContainer'
import InvoiceItemContainer from './app/invoice/components/detail/InvoiceItemContainer'
import LabelContainer from './app/label/components/LabelContainer'
import ShippingErrorContainer from './app/shippping-errors/components/ShippingErrorContainer'
import RelabelsContainer from './app/relabels/components/RelabelsContainer'
import SupplierValidationDetailContainer
    from "./app/supplier-validation-detail/components/SupplierValidationDetailContainer"
import getHistory from "./helpers/routing/getHistory"
import SupplierContainer from './app/supplier/components/SupplierContainer'

class App extends Component {
    render() {
        return (
            <Router history={getHistory()}>
                <Switch>
                    <Route path="/a/invoice-validation" exact component={InvoiceContainer}/>
                    <Route path="/a/invoice-validation/:id" exact component={InvoiceItemContainer}/>
                    <Route path="/a/export-errors-shipping" exact component={ShippingErrorContainer}/>
                    <Route path="/a/relabels" exact component={RelabelsContainer}/>
                    <Route path="/a/supplier-validation" exact component={SupplierContainer}/>
                    <Route path="/a/supplier-validation/:id" exact component={SupplierValidationDetailContainer}/>
                    <Route path="/" exact component={LabelContainer}/>
                </Switch>
            </Router>
        )
    }
}

export default App
